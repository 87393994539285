// extracted by mini-css-extract-plugin
export var buttonWrapper = "_form-module--buttonWrapper--QgTUa";
export var caution = "_form-module--caution--vlMK6";
export var formWrapper = "_form-module--formWrapper--BBHtf";
export var desc = "_form-module--desc--zizpc";
export var textInput = "_form-module--textInput--fn945";
export var textInputErr = "_form-module--textInputErr--Yt5n6";
export var textLabel = "_form-module--textLabel--zI6tn";
export var gender = "_form-module--gender--klDbk";
export var selectBox = "_form-module--selectBox--ifetR";
export var selectBoxErr = "_form-module--selectBoxErr--SlL+u";
export var dateWrapper = "_form-module--dateWrapper--fTYr1";
export var date = "_form-module--date--h1jFL";
export var dateInput = "_form-module--dateInput--Bmxc3";
export var dateInputErr = "_form-module--dateInputErr--L3ZeG";
export var agreement = "_form-module--agreement--1HN2U";
export var inputEx = "_form-module--inputEx--ST++P";
export var errMsg = "_form-module--errMsg--PwzrW";
export var errMsg2 = "_form-module--errMsg2--PsfnH";
export var confTtl = "_form-module--confTtl--my3zf";
export var thanksMsg = "_form-module--thanksMsg--sW3rK";
export var dlBtn = "_form-module--dlBtn--AqvrH";